import { initializeApp, getApps, getApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyD0E_oGpNduW6zrgbN7Ot5W6xXxInEBr0Q",
    authDomain: "simly-vfs-campaign.firebaseapp.com",
    projectId: "simly-vfs-campaign",
    storageBucket: "simly-vfs-campaign.appspot.com",
    messagingSenderId: "470653431567",
    appId: "1:470653431567:web:b99d179abaf58e0e00d87a",
    measurementId: "G-F3JZSLK9VX"
};

export const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
export const analytics = getAnalytics(app);